import GIFT from './path.gift';
import SERVICE from './path.service';

const NEWS = "/News";

export default {
  NEWS,
  GIFT,
  SERVICE,
  "HOME": "/",
  "PLAYER": "/Player",
  "ACTIVITY": "/Activity",
  "LANDSCAPE": "/Landscape",
  "ANNOUNCEMENT": "/Announcement",
};