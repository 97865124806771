import O18N from 'o18n';
import React from 'react';
import cx from 'classnames';

import locale from './locale';
import styles from './style.module.scss';

export default function ExternalList({ className, lang }) {

  const t = new O18N(locale, lang);
  const isHide = lang !== 'zh';
  return (
    <ul className={cx(styles.ExternalList, styles[className])}>
      {!isHide &&
        <>
        <li><a href="https://www2.ylgeopark.org.tw/admin/login/login.aspx">{t.volunteer}</a></li>
        <li><a href="https://www2.ylgeopark.org.tw/content/info/AcademicList.aspx">{t.research}</a></li>
        </>
      }
    </ul>
  );
}
