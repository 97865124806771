import O18N from 'o18n';
import cx from 'classnames';
import React, { useState, useEffect } from 'react';

import ExternalList from 'containers/App/components/ExternalList';

import locale from './locale';
import globalLocale from 'locale';
import styles from './style.module.scss';

import fb from './images/icon-fb@2x.png';
import owl from './images/icon-owl@2x.png';
import instagram from './images/icon-instagram@2x.png';

const isDesktop = () => document.body.clientWidth > 991;

export default function Footer({ lang, InfoData }) {
  const t = new O18N(globalLocale, lang);
  t.add(locale);

  const [ isDesktopSize, setIsDesktopSize ] = useState(isDesktop());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsDesktopSize(isDesktop());
    });
  }, []);

  const handkeGoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className={cx(styles.Footer)}>
      <div className={styles.backTop} onClick={handkeGoTop} />
      <div className="row">
        <div className="col-md-4">
          <a href="https://www.northguan-nsa.gov.tw" target="_blank" rel="noopener noreferrer">
            <div className={cx(styles.logo, styles.ngnsa)}></div>
          </a>
        </div>
        {/* <div className="col-md-4">
          <a href="http://www.yehliu.com.tw" target="_blank" rel="noopener noreferrer">
            <div className={cx(styles.logo, styles.ync)}></div>
          </a>
        </div> */}
        <div className="col-md-4 order-md-first">
          <a href="https://www.taiwan.net.tw/" target="_blank" rel="noopener noreferrer">
            <div className={cx(styles.logo, styles.thoa)}></div>
          </a>
        </div>
      </div>
      <div className="row">
        <div className={cx("col-md-6", styles.indent)}>
          <ul className={styles.mb}>
            {/* <li>
              {t.address}：{t.addressContent}
            </li> */}
            <li>
              地址：20744 臺灣新北市萬里區野柳里港東路167-1號
            </li>
            <li>
              Address：No.167-1, Kantung Rd., Yehliu Village, Wanli District, New Taipei City 20744, Taiwan, R.O.C.
            </li>
            <li>
              <a href="tel:+886224922016 ">{t.tel}：+886-2-24922016 </a>
            </li>
            <li>
              {t.fax}：+886-2-24924519 
            </li>
            <li>
              瀏覽人次：{InfoData.pageViewCount}
            </li>
          </ul>
        </div>
        <div className={styles.links}>
          <a href="https://www.facebook.com/yehliugeopark" target="_blank" rel="noopener noreferrer"><img src={fb} alt=""/></a>
          <a href="https://www.tripadvisor.com.tw/Attraction_Review-g13806954-d550250-Reviews-Yeh_Liu_Geo_Park-Wanli_New_Taipei.html" target="_blank" rel="noopener noreferrer"><img src={owl} alt=""/></a>
          <a href="https://www.instagram.com/yehliu_geopark/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt=""/></a>
        </div>
        <div className="col-md-6 order-md-first">
          <div className={styles.center}>{t.copyRight}</div>
          <div className={cx(styles.center, styles.mb2)}>Neo-Space International Inc.</div>
          {isDesktopSize && <ExternalList {...{
            lang,
            className: "inline",
          }} />}
        </div>
      </div>
    </footer>
  );
}
