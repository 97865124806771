import React from 'react';
import { hydrate, render } from "react-dom";
import './index.scss';
import App from 'containers/App';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
// import publicIp from 'public-ip';

// import * as serviceWorker from './serviceWorker';

// LogRocket.init('bnbkex/ylgeopark');
// setupLogRocketReact(LogRocket);

// publicIp.v4().then(ip => {
//   LogRocket.identify(ip);
// });

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate( <App />, rootElement);
} else {
  render( <App />, rootElement );
}

if (module.hot) {
  module.hot.accept('containers/App', () => {
    if (rootElement.hasChildNodes()) {
      hydrate( <App />, rootElement);
    } else {
      render( <App />, rootElement );
    }
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
