const HOME = '/Service';
const FAQ = `${HOME}/FAQ`;
const LIST = `${HOME}/Home`;
const QUERY = `${HOME}/Query`;
const SEARCH = `${HOME}/Search`;
const PEAK = `${HOME}/PeakReservation`;
const GUIDE = `${HOME}/GuideReservation`;
const ENVEDU = `${HOME}/EnvEduReservation`;

export default {
    FAQ,
    HOME,
    LIST,
    QUERY,
    SEARCH: {
      HOME: SEARCH,
    },
    PEAK: {
      HOME: `${PEAK}`
    },
    GUIDE: {
      HOME: `${GUIDE}`,
      INTRO: `${GUIDE}/Intro`,
      FORM: `${GUIDE}/Form`,
      CONFIRM: `${GUIDE}/Confirm`,
    },
    ENVEDU: {
      HOME: `${ENVEDU}`,
      INTRO: `${ENVEDU}/Intro`,
      FORM: `${ENVEDU}/Form`,
      PEER: `${ENVEDU}/Peer`,
      CONFIRM: `${ENVEDU}/Confirm`,
    },
};