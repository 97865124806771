/* eslint-disable import/first */

import cx from 'classnames';
import React, { useState, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

const Home = lazy(() => import('pages/Home' /* webpackChunkName: "Home" */));
const Player = lazy(() => import('pages/Player' /* webpackChunkName: "Player" */));
const Service = lazy(() => import('pages/Service' /* webpackChunkName: "Service" */));
const NoMatch = lazy(() => import('pages/NoMatch' /* webpackChunkName: "NoMatch" */));
const NewsList = lazy(() => import('pages/NewsList' /* webpackChunkName: "NewsList" */));
const GiftList = lazy(() => import('pages/GiftList' /* webpackChunkName: "GiftList" */));
const Landscape = lazy(() => import('pages/Landscape' /* webpackChunkName: "Landscape" */));
const NewsDetail = lazy(() => import('pages/NewsDetail' /* webpackChunkName: "NewsDetail" */));
const GiftDetail = lazy(() => import('pages/GiftDetail' /* webpackChunkName: "GiftDetail" */));

import {
  Header,
  Footer,
} from './components';

import {
  XHR,
  PATH,
} from 'consts';

import styles from './style.module.scss';
//import Info from 'pages/Player/Info';

function App() {
  const [ toggle, setToggle ] = useState(false);
  const [ weather, setWeather] = useState({});
  const [ marquees, setMarquees] = useState({});
  const [ pageViewCount, setPageViewCount] = useState("");
  const [ airQuality, setAirQuality ] = useState([]);
  const [ isOpenMenu, setIsOpenMenu ] = useState(false);

  // 判斷瀏覽器語言
  const browserLanguage = (navigator.language||navigator.userLanguage||navigator.browserLanguage||navigator.systemLanguage).toLowerCase();
  let sysLang = 'zh';
  if (browserLanguage === 'zh-tw') {
    sysLang = 'zh';
  } else if (browserLanguage === 'ko') {
    sysLang = 'kr';
  } else if (browserLanguage === 'ja-JP' || browserLanguage === 'ja') {
    sysLang = 'jp';
  } else {
    sysLang = 'en';
  }

  const [ lang, setLang ] = useState(localStorage.getItem('lang') || sysLang);


  useEffect(() => {
    // fetch(XHR.INSTANTENV)
      // .then(res => res.json())
      // .then(json => {
      //   setWeather(json.Weather);
      //   setMarquees(json.Marquees);
      //   setAirQuality(json.AirQuality);
      //   setPageViewCount(json.PageViewCount);
      // })
      
    ;
  }, []);

  const handleLangChange = lang => {
    setLang(lang);
  }

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const InfoData = {
    lang,
    weather,
    marquees,
    isOpenMenu,
    airQuality,
    pageViewCount,
    showBoard: false,
    parentClass: styles,
  }

  return (
    <BrowserRouter>
      <div className={styles.App}>
        <Header {...{ isOpenMenu, setIsOpenMenu, lang, handleLangChange }} />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/" exact render={() => <Home {...InfoData} />} />
            <Route exact path={`${PATH.NEWS}/:type/:id`} render={props => <NewsDetail {...props} {...InfoData} />} />
            <Route path={`${PATH.NEWS}/:type`} render={props => <NewsList {...props} {...InfoData} />} />
            <Redirect from={PATH.NEWS} to={`${PATH.NEWS}${PATH.ANNOUNCEMENT}`} />

            <Route path={`${PATH.GIFT.DETAIL}/:id`} render={props => <GiftDetail {...props} {...InfoData} />} />
            <Route path={`${PATH.GIFT.HOME}`} render={props => <GiftList {...props} {...InfoData} />} />
            
            {/* Sight / Ecology / Sea / Humanity / Video */}
            <Route path={`${PATH.LANDSCAPE}/:type`} render={props => <Landscape {...props} {...InfoData} />} />
            <Redirect from={PATH.LANDSCAPE} to={`${PATH.LANDSCAPE}/Sight`} />

            {/* Info / Restaurant */}
            <Route path={`${PATH.PLAYER}/:type`} render={props => <Player {...props} {...InfoData} />} />
            <Redirect from={PATH.PLAYER} to={`${PATH.PLAYER}/Info`} />
            
            {/* FAQ / EnvEduReservation / OrderTour */}
            <Route path={`${PATH.SERVICE.HOME}/:type`} render={props => <Service {...props} {...InfoData} />} />
            <Redirect from={PATH.SERVICE.HOME} to={PATH.SERVICE.FAQ} />
            
            <Route component={NoMatch} />
          </Switch>
        </Suspense>
        <Footer {...{ lang, InfoData }} />
        <ul className={cx(styles.expandContact, {[styles.toggle]: toggle})}>
          {toggle && <>
            {/* eslint-disable-next-line */}
            <li><a className={styles.phone} href="tel:+886224922016"></a></li>
            {/* eslint-disable-next-line */}
            <li><a className={styles.email} href="mailto:xxxx@gmail.com"></a></li>
            {/* eslint-disable-next-line */}
            <li><a className={styles.fb} href="http://facebook.com"></a></li>
          </>}
          <li><button onClick={handleToggle}><div>...</div></button></li>
        </ul>
      </div>
    </BrowserRouter>
  );
}

export default App;
