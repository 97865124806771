const BASE = `${process.env.REACT_APP_API_URL}/api`;
export default {
  BASE,
  INSTANTENV: `${BASE}/InstantEnv`,
  INFO: `${BASE}/Info`,
  GIFT: `${BASE}/Gift`,
  ARTICLE: `${BASE}/Article`,
  ACTIVITY: `${BASE}/Activity`,
  ANNOUNCEMENT: `${BASE}/Announcement`,
  SERVICE: {
    // "PEAK": "https://randomuser.me/api",
    PEAK: {
      POST: `${BASE}/PeakReservation`,
      DATE: `${BASE}/PeakReservation/AvailableDate`,
    },
    
    // "GUIDE": "https://randomuser.me/api",
    GUIDE: {
      POST: `${BASE}/GuideReservation`,
      LANG: `${BASE}/GuideReserveLanguage`,
      DATE: `${BASE}/GuideReservation/AvailableDate`,
    },
    
    ENVEDU: {
      // POST: "https://randomuser.me/api",
      CLASSNAME: `${BASE}/EnvEduProject`,
      POST: `${BASE}/EnvEduReservation`,
    },
    SEARCH: `${BASE}/Search`
  }
};