import O18N from 'o18n';
import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import PATH from 'consts/path';

import ExternalList from 'containers/App/components/ExternalList';

import locale from './locale';
import globalLocale from 'locale';
import styles from './style.module.scss';

const i18n = {
  zh: '中文',
  en: 'English',
  jp: '日本語',
  kr: '한국어',
};

const isDesktop = () => document.body.clientWidth > 991;

export default withRouter(function ({ isOpenMenu, setIsOpenMenu, lang, handleLangChange, location }) {

  const isHide = lang !== 'zh';
  const t = new O18N(globalLocale, lang);
  t.add(locale);

  const menu = React.createRef();
  const menuToggle = React.createRef();

  const [ isFold, setIsFold ] = useState(false);
  const [ country, setCountry ] = useState(lang);
  const [ isOpenLocal, setIsOpenLocal ] = useState(isDesktop());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsOpenLocal(isDesktop());
    });

    let lastScrollTop = 0;

    window.addEventListener("scroll", function() {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      setIsFold(st > lastScrollTop);
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }, false);
  }, []);

  const handleMenuToggle = ({ target }) => {
    setIsOpenMenu(target.checked);
  };

  const handleIsOpenLocal = () => {
    if(!isDesktop()) {
      setIsOpenLocal(!isOpenLocal);
    }
  };

  const closeMenu = () => {
    if(!isDesktop()) {
      setIsOpenMenu(false);
      setIsOpenLocal(false);
    }
  };

  const handleSetContry = lang => {
    closeMenu();
    setCountry(lang);
    handleLangChange(lang);
    localStorage.setItem('lang', lang);
  };

  const handleClick = e => {
    if(e.target === menu.current) {
      setIsOpenMenu(false);
    }
  };

  const currentURL = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  const isMenuActive = {
    gift: cx({[styles.active]: location.pathname.includes(PATH.GIFT.HOME)}),
    news: cx({[styles.active]: location.pathname.includes(PATH.NEWS)}),
    player: cx({[styles.active]: location.pathname.includes(PATH.PLAYER)}),
    landscape: cx({[styles.active]: location.pathname.includes(PATH.LANDSCAPE)}),
    service: cx({[styles.active]: location.pathname.includes(PATH.SERVICE.HOME)}),
  };

  return (
    <header className={styles.Header}>
      <div className={cx("container", styles.container)}>
        <Link to={PATH.HOME}>
          <div
            data-title="野柳地質公園"
            className={cx(styles.logo, {[styles.fold]: isFold})}
          />
        </Link>
        <input
          id="menuToggle"
          type="checkbox"
          ref={menuToggle}
          checked={isOpenMenu}
          onChange={handleMenuToggle} />
        <label
          htmlFor="menuToggle"
          className={cx(styles.burger, {[styles.fold]: isFold})}
        >
          <div className={styles.layer}></div>
          <div className={styles.layer}></div>
          <div className={styles.layer}></div>
        </label>
        <div className={styles.menu} ref={menu} onClick={handleClick}>
          <ul className={styles.main}>
            <li><Link onClick={closeMenu} className={isMenuActive.news} to={PATH.NEWS}>{t.news}</Link></li>
            <li><Link onClick={closeMenu} className={isMenuActive.landscape} to={PATH.LANDSCAPE}>{t.landscape}</Link></li>
            <li><Link onClick={closeMenu} className={isMenuActive.player} to={PATH.PLAYER}>{t.player}</Link></li>
            <li><Link onClick={closeMenu} className={isMenuActive.service} to={PATH.SERVICE.HOME}>{t.service}</Link></li>
            {!isHide &&
              <>
                <li><Link onClick={closeMenu} className={isMenuActive.gift} to={PATH.GIFT.HOME}>{t.gift}</Link></li>
              </>
            }
            {/* <li><a href="https://newtaipeicitybuskers.azurewebsites.net/Space/Detail/af9ef01c-2cc0-4739-8a97-71d54ba4462e" target="_blank" rel="noopener noreferrer">{t.ylcenter}</a></li> */}
          </ul>
          <ExternalList {...{ lang }} />
          <div className={cx(styles.sub, {[styles.expand]: isFold})}>
            <ul>
              <li className={styles.time}><Link onClick={closeMenu} to={`${PATH.PLAYER}/Info#Time`}>{t.time}</Link></li>
              <li className={styles.fare}><Link onClick={closeMenu} to={`${PATH.PLAYER}/Info#Fare`}>{t.fare}</Link></li>
              <li className={styles.traffic}><HashLink onClick={closeMenu} to={`${PATH.PLAYER}/Info#Traffic`}>{t.traffic}</HashLink></li>
              <li className={styles.intro}><HashLink onClick={closeMenu} to={`${PATH.PLAYER}/Info#Intro`}>{t.intro}</HashLink></li>
              {/* <li className={styles.booking}><Link onClick={closeMenu} to={PATH.SERVICE.LIST}>{t.booking}</Link></li> */}
              <li className={styles.ticketShop}><a href="https://ylgeopark.fonticket.com/" target="_blank" rel="noopener noreferrer">{t.ticketShop}</a></li>
            </ul>
          </div>
          <ul className={styles.localization}>
            <li>
              <Link
              to="/"
              className={cx(styles[country], styles.active)}
              onClick={handleIsOpenLocal}
              >
                {i18n[country]}
              </Link>
            </li>
            {Object.keys(i18n).map(key =>
              isOpenLocal && country !== key && (
                <li key={key}>
                  <Link
                    to={currentURL}
                    className={cx(styles[key])}
                    onClick={() => handleSetContry(key)}
                  >
                    {i18n[key]}
                  </Link>
                </li>
              )
            )}
          </ul>
          <div className={styles.tel}>
            {/* {t.tel}: 02-24922016 */}
            {t.tel}: {t.telNumber}
          </div>
          <div className={styles.menuBG} />
        </div>

      </div>
    </header>
  );
});
